
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as disaster_45supportz6tbXoSJhxMeta } from "/builds/marketing/website-2.0/pages/about-us/community/disaster-support.vue?macro=true";
import { default as fitted_45for_45work32h2SM2XfFMeta } from "/builds/marketing/website-2.0/pages/about-us/community/fitted-for-work.vue?macro=true";
import { default as Helping_45Communities_45Connectzde5lMNG59Meta } from "/builds/marketing/website-2.0/pages/about-us/community/Helping-Communities-Connect.vue?macro=true";
import { default as indexnoDzA7nwbRMeta } from "/builds/marketing/website-2.0/pages/about-us/community/index.vue?macro=true";
import { default as Small_45Change_45Big_45ChangeOZPB1JeDY3Meta } from "/builds/marketing/website-2.0/pages/about-us/community/Small-Change-Big-Change.vue?macro=true";
import { default as SustainabilityR6mWFaKNzHMeta } from "/builds/marketing/website-2.0/pages/about-us/community/Sustainability.vue?macro=true";
import { default as indexHp2wHjdNFZMeta } from "/builds/marketing/website-2.0/pages/about-us/index.vue?macro=true";
import { default as leadershipVZY8dAZAZJMeta } from "/builds/marketing/website-2.0/pages/about-us/leadership.vue?macro=true";
import { default as our_45historyJFy1dlHyyGMeta } from "/builds/marketing/website-2.0/pages/about-us/our-history.vue?macro=true";
import { default as indexsCLdejQZn1Meta } from "/builds/marketing/website-2.0/pages/accessibility/index.vue?macro=true";
import { default as _91_46_46_46slug_93Xz8w7ZQWwIMeta } from "/builds/marketing/website-2.0/pages/blog/[...slug].vue?macro=true";
import { default as all_45articlesA9Kdmbn2nCMeta } from "/builds/marketing/website-2.0/pages/blog/all-articles.vue?macro=true";
import { default as _91_46_46_46slug_93GHqGIlgnMcMeta } from "/builds/marketing/website-2.0/pages/blog/authors/[...slug].vue?macro=true";
import { default as indexUm4F07wHErMeta } from "/builds/marketing/website-2.0/pages/blog/authors/index.vue?macro=true";
import { default as index5syYkAoHTXMeta } from "/builds/marketing/website-2.0/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_937wguSWHmefMeta } from "/builds/marketing/website-2.0/pages/blog/tags/[...slug].vue?macro=true";
import { default as index9961x17OjOMeta } from "/builds/marketing/website-2.0/pages/blog/tags/index.vue?macro=true";
import { default as indexFbN7WidrA9Meta } from "/builds/marketing/website-2.0/pages/bundles/index.vue?macro=true";
import { default as business_45success_45teamyHKc3BTbbuMeta } from "/builds/marketing/website-2.0/pages/business/business-success-team.vue?macro=true";
import { default as contactv0O0GBofGcMeta } from "/builds/marketing/website-2.0/pages/business/contact.vue?macro=true";
import { default as indexfOsJVj5t22Meta } from "/builds/marketing/website-2.0/pages/business/index.vue?macro=true";
import { default as aussie_45fibre6zSJNJY9QxMeta } from "/builds/marketing/website-2.0/pages/business/internet/aussie-fibre.vue?macro=true";
import { default as nbn_45fttp_45upgradeL4aAuyuKwfMeta } from "/builds/marketing/website-2.0/pages/business/internet/nbn-fttp-upgrade.vue?macro=true";
import { default as network_45devicesBSJw1lWgx1Meta } from "/builds/marketing/website-2.0/pages/business/internet/network-devices.vue?macro=true";
import { default as akubrawDPfkpGtXaMeta } from "/builds/marketing/website-2.0/pages/business/lp/akubra.vue?macro=true";
import { default as aussie_45fibre_45ebookPjtYrVZMRLMeta } from "/builds/marketing/website-2.0/pages/business/lp/aussie-fibre-ebook.vue?macro=true";
import { default as eofyDv2Bwd3bDoMeta } from "/builds/marketing/website-2.0/pages/business/lp/eofy.vue?macro=true";
import { default as indexZSzwE8CYRuMeta } from "/builds/marketing/website-2.0/pages/business/lp/index.vue?macro=true";
import { default as _4g_45backupZWwIab36cVMeta } from "/builds/marketing/website-2.0/pages/business/mobile/4g-backup.vue?macro=true";
import { default as mobile_45broadbandJVI1gA4ZYTMeta } from "/builds/marketing/website-2.0/pages/business/mobile/mobile-broadband.vue?macro=true";
import { default as sim_45plansyt4h1bROqIMeta } from "/builds/marketing/website-2.0/pages/business/mobile/sim-plans.vue?macro=true";
import { default as indexxZIOAGjmIkMeta } from "/builds/marketing/website-2.0/pages/careers/index.vue?macro=true";
import { default as ace_45radioOHxv6ATCt4Meta } from "/builds/marketing/website-2.0/pages/case-studies/ace-radio.vue?macro=true";
import { default as akd_45softwoodsS4LFbYHSzHMeta } from "/builds/marketing/website-2.0/pages/case-studies/akd-softwoods.vue?macro=true";
import { default as akubraKq9gwHJKEqMeta } from "/builds/marketing/website-2.0/pages/case-studies/akubra.vue?macro=true";
import { default as bairnsdale_45internationalfqAAcEm2AQMeta } from "/builds/marketing/website-2.0/pages/case-studies/bairnsdale-international.vue?macro=true";
import { default as cfa_45hoppers_45crossinguHQbfa52JpMeta } from "/builds/marketing/website-2.0/pages/case-studies/cfa-hoppers-crossing.vue?macro=true";
import { default as cumulusg79O7X3YmMMeta } from "/builds/marketing/website-2.0/pages/case-studies/cumulus.vue?macro=true";
import { default as foodworks23X6814m2bMeta } from "/builds/marketing/website-2.0/pages/case-studies/foodworks.vue?macro=true";
import { default as great_45southernpjE1IShe84Meta } from "/builds/marketing/website-2.0/pages/case-studies/great-southern.vue?macro=true";
import { default as harris_45woodL0s7RBMF1vMeta } from "/builds/marketing/website-2.0/pages/case-studies/harris-wood.vue?macro=true";
import { default as index7Jc9LI3LJqMeta } from "/builds/marketing/website-2.0/pages/case-studies/index.vue?macro=true";
import { default as investigator_45collegeGl9dL3IwHgMeta } from "/builds/marketing/website-2.0/pages/case-studies/investigator-college.vue?macro=true";
import { default as james_45cook_45uniCmzVdpRrCPMeta } from "/builds/marketing/website-2.0/pages/case-studies/james-cook-uni.vue?macro=true";
import { default as landmark_45internationalCDCyerhIJJMeta } from "/builds/marketing/website-2.0/pages/case-studies/landmark-international.vue?macro=true";
import { default as latrobe_45regional_45healthSVcpXVETfaMeta } from "/builds/marketing/website-2.0/pages/case-studies/latrobe-regional-health.vue?macro=true";
import { default as mundaring_45shireG7tnclYQwfMeta } from "/builds/marketing/website-2.0/pages/case-studies/mundaring-shire.vue?macro=true";
import { default as national_45storage2bo8hAxsCsMeta } from "/builds/marketing/website-2.0/pages/case-studies/national-storage.vue?macro=true";
import { default as novao5rtpcN75uMeta } from "/builds/marketing/website-2.0/pages/case-studies/nova.vue?macro=true";
import { default as townsville_45dioceseaO0Rph708PMeta } from "/builds/marketing/website-2.0/pages/case-studies/townsville-diocese.vue?macro=true";
import { default as vicses_45nillumbikvEFhGLKa8RMeta } from "/builds/marketing/website-2.0/pages/case-studies/vicses-nillumbik.vue?macro=true";
import { default as westpacq0Cb7v8VzHMeta } from "/builds/marketing/website-2.0/pages/case-studies/westpac.vue?macro=true";
import { default as complaintljdHXGxNIaMeta } from "/builds/marketing/website-2.0/pages/contact/complaint.vue?macro=true";
import { default as index700UBBIIsOMeta } from "/builds/marketing/website-2.0/pages/contact/index.vue?macro=true";
import { default as indexwRxogJjeKhMeta } from "/builds/marketing/website-2.0/pages/customer/index.vue?macro=true";
import { default as carbon6dYojSqlcCMeta } from "/builds/marketing/website-2.0/pages/enterprise/carbon.vue?macro=true";
import { default as cloudxiDNtaYvssMeta } from "/builds/marketing/website-2.0/pages/enterprise/cloud.vue?macro=true";
import { default as company_45security65HnT8vevRMeta } from "/builds/marketing/website-2.0/pages/enterprise/company-security.vue?macro=true";
import { default as constructionflRVxWvcpxMeta } from "/builds/marketing/website-2.0/pages/enterprise/construction.vue?macro=true";
import { default as contact1liFngwKnIMeta } from "/builds/marketing/website-2.0/pages/enterprise/contact.vue?macro=true";
import { default as data_45securityqOIS50Rg8mMeta } from "/builds/marketing/website-2.0/pages/enterprise/data-security.vue?macro=true";
import { default as educationMu5KqK2DYLMeta } from "/builds/marketing/website-2.0/pages/enterprise/education.vue?macro=true";
import { default as finance8TTHc2b967Meta } from "/builds/marketing/website-2.0/pages/enterprise/finance.vue?macro=true";
import { default as healthcareThCIfBz6cUMeta } from "/builds/marketing/website-2.0/pages/enterprise/healthcare.vue?macro=true";
import { default as indexng3ovf8DXFMeta } from "/builds/marketing/website-2.0/pages/enterprise/index.vue?macro=true";
import { default as enterprise_45ethernetEZ61yLU0whMeta } from "/builds/marketing/website-2.0/pages/enterprise/internet/enterprise-ethernet.vue?macro=true";
import { default as indextYC91Mn5EhMeta } from "/builds/marketing/website-2.0/pages/enterprise/internet/index.vue?macro=true";
import { default as nbn_45plansFSHtKWZb4JMeta } from "/builds/marketing/website-2.0/pages/enterprise/internet/nbn-plans.vue?macro=true";
import { default as it_45complexityibUXdzhHTgMeta } from "/builds/marketing/website-2.0/pages/enterprise/it-complexity.vue?macro=true";
import { default as itMrraMJoXEcMeta } from "/builds/marketing/website-2.0/pages/enterprise/it.vue?macro=true";
import { default as local_45government25A0N7f19pMeta } from "/builds/marketing/website-2.0/pages/enterprise/local-government.vue?macro=true";
import { default as index1c1JbWseDPMeta } from "/builds/marketing/website-2.0/pages/enterprise/lp/index.vue?macro=true";
import { default as vmwareP6zntzXY0dMeta } from "/builds/marketing/website-2.0/pages/enterprise/lp/vmware.vue?macro=true";
import { default as mobile_45voiceU59lDJ6H8IMeta } from "/builds/marketing/website-2.0/pages/enterprise/mobile-voice.vue?macro=true";
import { default as network_45performancecQUwRfz5wgMeta } from "/builds/marketing/website-2.0/pages/enterprise/network-performance.vue?macro=true";
import { default as data_45centre_45connect4z1rGi2rXFMeta } from "/builds/marketing/website-2.0/pages/enterprise/network/data-centre-connect.vue?macro=true";
import { default as hosted_45firewallHMjmWzJ2AfMeta } from "/builds/marketing/website-2.0/pages/enterprise/network/hosted-firewall.vue?macro=true";
import { default as indexIpcSJLMDi5Meta } from "/builds/marketing/website-2.0/pages/enterprise/network/index.vue?macro=true";
import { default as private_45ipmjTKxCSFz2Meta } from "/builds/marketing/website-2.0/pages/enterprise/network/private-ip.vue?macro=true";
import { default as sd_45wankR7z0IL1NiMeta } from "/builds/marketing/website-2.0/pages/enterprise/network/sd-wan.vue?macro=true";
import { default as operator_45connect7PpiFCaHi9Meta } from "/builds/marketing/website-2.0/pages/enterprise/operator-connect.vue?macro=true";
import { default as professional_45servicesONOWpxsNsFMeta } from "/builds/marketing/website-2.0/pages/enterprise/professional-services.vue?macro=true";
import { default as retailQ9YOeP7C8YMeta } from "/builds/marketing/website-2.0/pages/enterprise/retail.vue?macro=true";
import { default as indexLTDfnPkrNiMeta } from "/builds/marketing/website-2.0/pages/enterprise/security/index.vue?macro=true";
import { default as sasenTAMrcAINSMeta } from "/builds/marketing/website-2.0/pages/enterprise/security/sase.vue?macro=true";
import { default as staff_45retentionjfCiNtCCL9Meta } from "/builds/marketing/website-2.0/pages/enterprise/staff-retention.vue?macro=true";
import { default as starlinkB7EhHKum3yMeta } from "/builds/marketing/website-2.0/pages/enterprise/starlink.vue?macro=true";
import { default as upgrade_45techhQV2NkM73wMeta } from "/builds/marketing/website-2.0/pages/enterprise/upgrade-tech.vue?macro=true";
import { default as faultJjBPmLSnSbMeta } from "/builds/marketing/website-2.0/pages/feedback/fault.vue?macro=true";
import { default as indexWVP57kL370Meta } from "/builds/marketing/website-2.0/pages/feedback/index.vue?macro=true";
import { default as salesHZiiZYIiU5Meta } from "/builds/marketing/website-2.0/pages/feedback/sales.vue?macro=true";
import { default as _91_46_46_46slug_93PvYSCIDrL0Meta } from "/builds/marketing/website-2.0/pages/help-centre/accounts-and-billing/[...slug].vue?macro=true";
import { default as indexyvOKrZ6qUKMeta } from "/builds/marketing/website-2.0/pages/help-centre/accounts-and-billing/index.vue?macro=true";
import { default as indexN0vLMdZJNjMeta } from "/builds/marketing/website-2.0/pages/help-centre/index.vue?macro=true";
import { default as _91_46_46_46slug_93n92p7v72f5Meta } from "/builds/marketing/website-2.0/pages/help-centre/internet/[...slug].vue?macro=true";
import { default as indexYpFhMJJnjxMeta } from "/builds/marketing/website-2.0/pages/help-centre/internet/index.vue?macro=true";
import { default as _91_46_46_46slug_93U9DETkmqFoMeta } from "/builds/marketing/website-2.0/pages/help-centre/mobile/[...slug].vue?macro=true";
import { default as indexdgFB6iPGe2Meta } from "/builds/marketing/website-2.0/pages/help-centre/mobile/index.vue?macro=true";
import { default as _91_46_46_46slug_93bkllzXhzyaMeta } from "/builds/marketing/website-2.0/pages/help-centre/phone/[...slug].vue?macro=true";
import { default as indexjTtYGuGqZCMeta } from "/builds/marketing/website-2.0/pages/help-centre/phone/index.vue?macro=true";
import { default as voip_45international_45call_45rates2V1sUzLmb8Meta } from "/builds/marketing/website-2.0/pages/help-centre/phone/voip-international-call-rates.vue?macro=true";
import { default as _91_46_46_46slug_93ziaRGpBrtMMeta } from "/builds/marketing/website-2.0/pages/help-centre/tags/[...slug].vue?macro=true";
import { default as indexkrxJAasl42Meta } from "/builds/marketing/website-2.0/pages/help-centre/tags/index.vue?macro=true";
import { default as index54181AvTluMeta } from "/builds/marketing/website-2.0/pages/index.vue?macro=true";
import { default as entertainmenttC1Hg4ixKhMeta } from "/builds/marketing/website-2.0/pages/internet/entertainment.vue?macro=true";
import { default as gamers_45nbn4XHBJGaZnhMeta } from "/builds/marketing/website-2.0/pages/internet/gamers-nbn.vue?macro=true";
import { default as hardwareYF5OkDAaBlMeta } from "/builds/marketing/website-2.0/pages/internet/hardware.vue?macro=true";
import { default as home_45phoneoFzNfFuRV9Meta } from "/builds/marketing/website-2.0/pages/internet/home-phone.vue?macro=true";
import { default as nbn_45fttp_45upgrade8tLpQ1E5fSMeta } from "/builds/marketing/website-2.0/pages/internet/nbn-fttp-upgrade.vue?macro=true";
import { default as seniors_45nbnCvZUrg1oSiMeta } from "/builds/marketing/website-2.0/pages/internet/seniors-nbn.vue?macro=true";
import { default as telstraqxxixewjrDMeta } from "/builds/marketing/website-2.0/pages/internet/telstra.vue?macro=true";
import { default as indexmUYS7ALOGHMeta } from "/builds/marketing/website-2.0/pages/investor-centre/index.vue?macro=true";
import { default as indexP5lMxajsLuMeta } from "/builds/marketing/website-2.0/pages/legal/index.vue?macro=true";
import { default as privacy_45policyKhKgo6NQK4Meta } from "/builds/marketing/website-2.0/pages/legal/privacy-policy.vue?macro=true";
import { default as big_45tech_45competition2fERJqV6NkMeta } from "/builds/marketing/website-2.0/pages/lp/big-tech-competition.vue?macro=true";
import { default as black_45fridayDu4vUj9GyOMeta } from "/builds/marketing/website-2.0/pages/lp/black-friday.vue?macro=true";
import { default as eofyi2Gi5xeREWMeta } from "/builds/marketing/website-2.0/pages/lp/eofy.vue?macro=true";
import { default as gamers_45survey_452024ce0ozp648CMeta } from "/builds/marketing/website-2.0/pages/lp/gamers-survey-2024.vue?macro=true";
import { default as indexqt83p83KlnMeta } from "/builds/marketing/website-2.0/pages/lp/index.vue?macro=true";
import { default as _91_46_46_46slug_93F3MfSF71HIMeta } from "/builds/marketing/website-2.0/pages/media-centre/[...slug].vue?macro=true";
import { default as all_45articlesURGhlcvROxMeta } from "/builds/marketing/website-2.0/pages/media-centre/all-articles.vue?macro=true";
import { default as indexLGfeDK1iPFMeta } from "/builds/marketing/website-2.0/pages/media-centre/index.vue?macro=true";
import { default as _91slug_93LUXd0o8KB9Meta } from "/builds/marketing/website-2.0/pages/mobile/[slug].vue?macro=true";
import { default as _3g_45network_45changeM923bvYQBeMeta } from "/builds/marketing/website-2.0/pages/mobile/3g-network-change.vue?macro=true";
import { default as data_45only_45simQW2jkfHZfwMeta } from "/builds/marketing/website-2.0/pages/mobile/data-only-sim.vue?macro=true";
import { default as sim_45only_45plansNcxemFmjsBMeta } from "/builds/marketing/website-2.0/pages/mobile/sim-only-plans.vue?macro=true";
import { default as indexrk7yiD6spTMeta } from "/builds/marketing/website-2.0/pages/moving-home/index.vue?macro=true";
import { default as indexHitsPJdVD7Meta } from "/builds/marketing/website-2.0/pages/myaussie-app/index.vue?macro=true";
import { default as connection_45insightspArtCrjusNMeta } from "/builds/marketing/website-2.0/pages/network/connection-insights.vue?macro=true";
import { default as cvc_45graphs2EDnQU2ZFjMeta } from "/builds/marketing/website-2.0/pages/network/cvc-graphs.vue?macro=true";
import { default as indexSKlEN7ybp5Meta } from "/builds/marketing/website-2.0/pages/network/index.vue?macro=true";
import { default as mapELrQ7NDt6mMeta } from "/builds/marketing/website-2.0/pages/network/map.vue?macro=true";
import { default as mobile_45coverage_45mapho4r7LB9DJMeta } from "/builds/marketing/website-2.0/pages/network/mobile-coverage-map.vue?macro=true";
import { default as outagesHAE6nKNu1hMeta } from "/builds/marketing/website-2.0/pages/network/outages.vue?macro=true";
import { default as ping_45timesEuioZApkuMMeta } from "/builds/marketing/website-2.0/pages/network/ping-times.vue?macro=true";
import { default as indexOn6S0vj6D3Meta } from "/builds/marketing/website-2.0/pages/our-products/index.vue?macro=true";
import { default as indexc7CSKyxkCVMeta } from "/builds/marketing/website-2.0/pages/refer-a-friend/index.vue?macro=true";
import { default as indexhToZ0jaLyEMeta } from "/builds/marketing/website-2.0/pages/reviews/index.vue?macro=true";
import { default as indexcMDGRgdAPHMeta } from "/builds/marketing/website-2.0/pages/search/index.vue?macro=true";
import { default as indexW3krUBdZfkMeta } from "/builds/marketing/website-2.0/pages/speed/index.vue?macro=true";
import { default as index915KlFhy1yMeta } from "/builds/marketing/website-2.0/pages/ssbi/index.vue?macro=true";
import { default as activate_45simcOLuqUquNYMeta } from "/builds/marketing/website-2.0/pages/tools/activate-sim.vue?macro=true";
import { default as fibre_45operationsqFI0jryGl4Meta } from "/builds/marketing/website-2.0/pages/tools/fibre-operations.vue?macro=true";
import { default as release_45formehKlCRtviDMeta } from "/builds/marketing/website-2.0/pages/tools/release-form.vue?macro=true";
import { default as become_45a_45partnerCPM3cuy6d6Meta } from "/builds/marketing/website-2.0/pages/wholesale/become-a-partner.vue?macro=true";
import { default as carbonEdFdW5COkJMeta } from "/builds/marketing/website-2.0/pages/wholesale/carbon.vue?macro=true";
import { default as indexrBgHfzkCDLMeta } from "/builds/marketing/website-2.0/pages/wholesale/carrier/index.vue?macro=true";
import { default as indexkstw7uJgRtMeta } from "/builds/marketing/website-2.0/pages/wholesale/index.vue?macro=true";
import { default as index6NKnQS9wupMeta } from "/builds/marketing/website-2.0/pages/wholesale/lp/index.vue?macro=true";
import { default as number_45portingqlewZRzrsnMeta } from "/builds/marketing/website-2.0/pages/wholesale/lp/number-porting.vue?macro=true";
import { default as indexMCefLZSiTcMeta } from "/builds/marketing/website-2.0/pages/wholesale/msp/index.vue?macro=true";
import { default as partner_45programGR3T8Ka8MHMeta } from "/builds/marketing/website-2.0/pages/wholesale/partner-program.vue?macro=true";
import { default as aussie_45fibregJgbrNOX7PMeta } from "/builds/marketing/website-2.0/pages/wholesale/services/aussie-fibre.vue?macro=true";
import { default as dataODP4GwE6krMeta } from "/builds/marketing/website-2.0/pages/wholesale/services/data.vue?macro=true";
import { default as network_45solutions3MnIHK5wuXMeta } from "/builds/marketing/website-2.0/pages/wholesale/services/network-solutions.vue?macro=true";
import { default as voice_45carrierwFfTzzvB06Meta } from "/builds/marketing/website-2.0/pages/wholesale/services/voice-carrier.vue?macro=true";
import { default as voiceniTEBJBsW0Meta } from "/builds/marketing/website-2.0/pages/wholesale/services/voice.vue?macro=true";
import { default as white_45labelu8DjHI0dYJMeta } from "/builds/marketing/website-2.0/pages/wholesale/white-label.vue?macro=true";
export default [
  {
    name: "about-us-community-disaster-support",
    path: "/about-us/community/disaster-support",
    component: () => import("/builds/marketing/website-2.0/pages/about-us/community/disaster-support.vue")
  },
  {
    name: "about-us-community-fitted-for-work",
    path: "/about-us/community/fitted-for-work",
    component: () => import("/builds/marketing/website-2.0/pages/about-us/community/fitted-for-work.vue")
  },
  {
    name: "about-us-community-Helping-Communities-Connect",
    path: "/about-us/community/Helping-Communities-Connect",
    component: () => import("/builds/marketing/website-2.0/pages/about-us/community/Helping-Communities-Connect.vue")
  },
  {
    name: "about-us-community",
    path: "/about-us/community",
    component: () => import("/builds/marketing/website-2.0/pages/about-us/community/index.vue")
  },
  {
    name: "about-us-community-Small-Change-Big-Change",
    path: "/about-us/community/Small-Change-Big-Change",
    component: () => import("/builds/marketing/website-2.0/pages/about-us/community/Small-Change-Big-Change.vue")
  },
  {
    name: "about-us-community-Sustainability",
    path: "/about-us/community/Sustainability",
    component: () => import("/builds/marketing/website-2.0/pages/about-us/community/Sustainability.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/builds/marketing/website-2.0/pages/about-us/index.vue")
  },
  {
    name: "about-us-leadership",
    path: "/about-us/leadership",
    component: () => import("/builds/marketing/website-2.0/pages/about-us/leadership.vue")
  },
  {
    name: "about-us-our-history",
    path: "/about-us/our-history",
    component: () => import("/builds/marketing/website-2.0/pages/about-us/our-history.vue")
  },
  {
    name: "accessibility",
    path: "/accessibility",
    component: () => import("/builds/marketing/website-2.0/pages/accessibility/index.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    component: () => import("/builds/marketing/website-2.0/pages/blog/[...slug].vue")
  },
  {
    name: "blog-all-articles",
    path: "/blog/all-articles",
    component: () => import("/builds/marketing/website-2.0/pages/blog/all-articles.vue")
  },
  {
    name: "blog-authors-slug",
    path: "/blog/authors/:slug(.*)*",
    component: () => import("/builds/marketing/website-2.0/pages/blog/authors/[...slug].vue")
  },
  {
    name: "blog-authors",
    path: "/blog/authors",
    component: () => import("/builds/marketing/website-2.0/pages/blog/authors/index.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/builds/marketing/website-2.0/pages/blog/index.vue")
  },
  {
    name: "blog-tags-slug",
    path: "/blog/tags/:slug(.*)*",
    component: () => import("/builds/marketing/website-2.0/pages/blog/tags/[...slug].vue")
  },
  {
    name: "blog-tags",
    path: "/blog/tags",
    component: () => import("/builds/marketing/website-2.0/pages/blog/tags/index.vue")
  },
  {
    name: "bundles",
    path: "/bundles",
    component: () => import("/builds/marketing/website-2.0/pages/bundles/index.vue")
  },
  {
    name: "business-business-success-team",
    path: "/business/business-success-team",
    component: () => import("/builds/marketing/website-2.0/pages/business/business-success-team.vue")
  },
  {
    name: "business-contact",
    path: "/business/contact",
    component: () => import("/builds/marketing/website-2.0/pages/business/contact.vue")
  },
  {
    name: "business",
    path: "/business",
    component: () => import("/builds/marketing/website-2.0/pages/business/index.vue")
  },
  {
    name: "business-internet-aussie-fibre",
    path: "/business/internet/aussie-fibre",
    component: () => import("/builds/marketing/website-2.0/pages/business/internet/aussie-fibre.vue")
  },
  {
    name: "business-internet-nbn-fttp-upgrade",
    path: "/business/internet/nbn-fttp-upgrade",
    component: () => import("/builds/marketing/website-2.0/pages/business/internet/nbn-fttp-upgrade.vue")
  },
  {
    name: "business-internet-network-devices",
    path: "/business/internet/network-devices",
    component: () => import("/builds/marketing/website-2.0/pages/business/internet/network-devices.vue")
  },
  {
    name: "business-lp-akubra",
    path: "/business/lp/akubra",
    component: () => import("/builds/marketing/website-2.0/pages/business/lp/akubra.vue")
  },
  {
    name: "business-lp-aussie-fibre-ebook",
    path: "/business/lp/aussie-fibre-ebook",
    component: () => import("/builds/marketing/website-2.0/pages/business/lp/aussie-fibre-ebook.vue")
  },
  {
    name: "business-lp-eofy",
    path: "/business/lp/eofy",
    component: () => import("/builds/marketing/website-2.0/pages/business/lp/eofy.vue")
  },
  {
    name: "business-lp",
    path: "/business/lp",
    component: () => import("/builds/marketing/website-2.0/pages/business/lp/index.vue")
  },
  {
    name: "business-mobile-4g-backup",
    path: "/business/mobile/4g-backup",
    component: () => import("/builds/marketing/website-2.0/pages/business/mobile/4g-backup.vue")
  },
  {
    name: "business-mobile-mobile-broadband",
    path: "/business/mobile/mobile-broadband",
    component: () => import("/builds/marketing/website-2.0/pages/business/mobile/mobile-broadband.vue")
  },
  {
    name: "business-mobile-sim-plans",
    path: "/business/mobile/sim-plans",
    component: () => import("/builds/marketing/website-2.0/pages/business/mobile/sim-plans.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/builds/marketing/website-2.0/pages/careers/index.vue")
  },
  {
    name: "case-studies-ace-radio",
    path: "/case-studies/ace-radio",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/ace-radio.vue")
  },
  {
    name: "case-studies-akd-softwoods",
    path: "/case-studies/akd-softwoods",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/akd-softwoods.vue")
  },
  {
    name: "case-studies-akubra",
    path: "/case-studies/akubra",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/akubra.vue")
  },
  {
    name: "case-studies-bairnsdale-international",
    path: "/case-studies/bairnsdale-international",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/bairnsdale-international.vue")
  },
  {
    name: "case-studies-cfa-hoppers-crossing",
    path: "/case-studies/cfa-hoppers-crossing",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/cfa-hoppers-crossing.vue")
  },
  {
    name: "case-studies-cumulus",
    path: "/case-studies/cumulus",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/cumulus.vue")
  },
  {
    name: "case-studies-foodworks",
    path: "/case-studies/foodworks",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/foodworks.vue")
  },
  {
    name: "case-studies-great-southern",
    path: "/case-studies/great-southern",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/great-southern.vue")
  },
  {
    name: "case-studies-harris-wood",
    path: "/case-studies/harris-wood",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/harris-wood.vue")
  },
  {
    name: "case-studies",
    path: "/case-studies",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/index.vue")
  },
  {
    name: "case-studies-investigator-college",
    path: "/case-studies/investigator-college",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/investigator-college.vue")
  },
  {
    name: "case-studies-james-cook-uni",
    path: "/case-studies/james-cook-uni",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/james-cook-uni.vue")
  },
  {
    name: "case-studies-landmark-international",
    path: "/case-studies/landmark-international",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/landmark-international.vue")
  },
  {
    name: "case-studies-latrobe-regional-health",
    path: "/case-studies/latrobe-regional-health",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/latrobe-regional-health.vue")
  },
  {
    name: "case-studies-mundaring-shire",
    path: "/case-studies/mundaring-shire",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/mundaring-shire.vue")
  },
  {
    name: "case-studies-national-storage",
    path: "/case-studies/national-storage",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/national-storage.vue")
  },
  {
    name: "case-studies-nova",
    path: "/case-studies/nova",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/nova.vue")
  },
  {
    name: "case-studies-townsville-diocese",
    path: "/case-studies/townsville-diocese",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/townsville-diocese.vue")
  },
  {
    name: "case-studies-vicses-nillumbik",
    path: "/case-studies/vicses-nillumbik",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/vicses-nillumbik.vue")
  },
  {
    name: "case-studies-westpac",
    path: "/case-studies/westpac",
    component: () => import("/builds/marketing/website-2.0/pages/case-studies/westpac.vue")
  },
  {
    name: "contact-complaint",
    path: "/contact/complaint",
    component: () => import("/builds/marketing/website-2.0/pages/contact/complaint.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/marketing/website-2.0/pages/contact/index.vue")
  },
  {
    name: "customer",
    path: "/customer",
    component: () => import("/builds/marketing/website-2.0/pages/customer/index.vue")
  },
  {
    name: "enterprise-carbon",
    path: "/enterprise/carbon",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/carbon.vue")
  },
  {
    name: "enterprise-cloud",
    path: "/enterprise/cloud",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/cloud.vue")
  },
  {
    name: "enterprise-company-security",
    path: "/enterprise/company-security",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/company-security.vue")
  },
  {
    name: "enterprise-construction",
    path: "/enterprise/construction",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/construction.vue")
  },
  {
    name: "enterprise-contact",
    path: "/enterprise/contact",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/contact.vue")
  },
  {
    name: "enterprise-data-security",
    path: "/enterprise/data-security",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/data-security.vue")
  },
  {
    name: "enterprise-education",
    path: "/enterprise/education",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/education.vue")
  },
  {
    name: "enterprise-finance",
    path: "/enterprise/finance",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/finance.vue")
  },
  {
    name: "enterprise-healthcare",
    path: "/enterprise/healthcare",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/healthcare.vue")
  },
  {
    name: "enterprise",
    path: "/enterprise",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/index.vue")
  },
  {
    name: "enterprise-internet-enterprise-ethernet",
    path: "/enterprise/internet/enterprise-ethernet",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/internet/enterprise-ethernet.vue")
  },
  {
    name: "enterprise-internet",
    path: "/enterprise/internet",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/internet/index.vue")
  },
  {
    name: "enterprise-internet-nbn-plans",
    path: "/enterprise/internet/nbn-plans",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/internet/nbn-plans.vue")
  },
  {
    name: "enterprise-it-complexity",
    path: "/enterprise/it-complexity",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/it-complexity.vue")
  },
  {
    name: "enterprise-it",
    path: "/enterprise/it",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/it.vue")
  },
  {
    name: "enterprise-local-government",
    path: "/enterprise/local-government",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/local-government.vue")
  },
  {
    name: "enterprise-lp",
    path: "/enterprise/lp",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/lp/index.vue")
  },
  {
    name: "enterprise-lp-vmware",
    path: "/enterprise/lp/vmware",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/lp/vmware.vue")
  },
  {
    name: "enterprise-mobile-voice",
    path: "/enterprise/mobile-voice",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/mobile-voice.vue")
  },
  {
    name: "enterprise-network-performance",
    path: "/enterprise/network-performance",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/network-performance.vue")
  },
  {
    name: "enterprise-network-data-centre-connect",
    path: "/enterprise/network/data-centre-connect",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/network/data-centre-connect.vue")
  },
  {
    name: "enterprise-network-hosted-firewall",
    path: "/enterprise/network/hosted-firewall",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/network/hosted-firewall.vue")
  },
  {
    name: "enterprise-network",
    path: "/enterprise/network",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/network/index.vue")
  },
  {
    name: "enterprise-network-private-ip",
    path: "/enterprise/network/private-ip",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/network/private-ip.vue")
  },
  {
    name: "enterprise-network-sd-wan",
    path: "/enterprise/network/sd-wan",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/network/sd-wan.vue")
  },
  {
    name: "enterprise-operator-connect",
    path: "/enterprise/operator-connect",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/operator-connect.vue")
  },
  {
    name: "enterprise-professional-services",
    path: "/enterprise/professional-services",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/professional-services.vue")
  },
  {
    name: "enterprise-retail",
    path: "/enterprise/retail",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/retail.vue")
  },
  {
    name: "enterprise-security",
    path: "/enterprise/security",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/security/index.vue")
  },
  {
    name: "enterprise-security-sase",
    path: "/enterprise/security/sase",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/security/sase.vue")
  },
  {
    name: "enterprise-staff-retention",
    path: "/enterprise/staff-retention",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/staff-retention.vue")
  },
  {
    name: "enterprise-starlink",
    path: "/enterprise/starlink",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/starlink.vue")
  },
  {
    name: "enterprise-upgrade-tech",
    path: "/enterprise/upgrade-tech",
    component: () => import("/builds/marketing/website-2.0/pages/enterprise/upgrade-tech.vue")
  },
  {
    name: "feedback-fault",
    path: "/feedback/fault",
    component: () => import("/builds/marketing/website-2.0/pages/feedback/fault.vue")
  },
  {
    name: "feedback",
    path: "/feedback",
    component: () => import("/builds/marketing/website-2.0/pages/feedback/index.vue")
  },
  {
    name: "feedback-sales",
    path: "/feedback/sales",
    component: () => import("/builds/marketing/website-2.0/pages/feedback/sales.vue")
  },
  {
    name: "help-centre-accounts-and-billing-slug",
    path: "/help-centre/accounts-and-billing/:slug(.*)*",
    component: () => import("/builds/marketing/website-2.0/pages/help-centre/accounts-and-billing/[...slug].vue")
  },
  {
    name: "help-centre-accounts-and-billing",
    path: "/help-centre/accounts-and-billing",
    component: () => import("/builds/marketing/website-2.0/pages/help-centre/accounts-and-billing/index.vue")
  },
  {
    name: "help-centre",
    path: "/help-centre",
    component: () => import("/builds/marketing/website-2.0/pages/help-centre/index.vue")
  },
  {
    name: "help-centre-internet-slug",
    path: "/help-centre/internet/:slug(.*)*",
    component: () => import("/builds/marketing/website-2.0/pages/help-centre/internet/[...slug].vue")
  },
  {
    name: "help-centre-internet",
    path: "/help-centre/internet",
    component: () => import("/builds/marketing/website-2.0/pages/help-centre/internet/index.vue")
  },
  {
    name: "help-centre-mobile-slug",
    path: "/help-centre/mobile/:slug(.*)*",
    component: () => import("/builds/marketing/website-2.0/pages/help-centre/mobile/[...slug].vue")
  },
  {
    name: "help-centre-mobile",
    path: "/help-centre/mobile",
    component: () => import("/builds/marketing/website-2.0/pages/help-centre/mobile/index.vue")
  },
  {
    name: "help-centre-phone-slug",
    path: "/help-centre/phone/:slug(.*)*",
    component: () => import("/builds/marketing/website-2.0/pages/help-centre/phone/[...slug].vue")
  },
  {
    name: "help-centre-phone",
    path: "/help-centre/phone",
    component: () => import("/builds/marketing/website-2.0/pages/help-centre/phone/index.vue")
  },
  {
    name: "help-centre-phone-voip-international-call-rates",
    path: "/help-centre/phone/voip-international-call-rates",
    component: () => import("/builds/marketing/website-2.0/pages/help-centre/phone/voip-international-call-rates.vue")
  },
  {
    name: "help-centre-tags-slug",
    path: "/help-centre/tags/:slug(.*)*",
    component: () => import("/builds/marketing/website-2.0/pages/help-centre/tags/[...slug].vue")
  },
  {
    name: "help-centre-tags",
    path: "/help-centre/tags",
    component: () => import("/builds/marketing/website-2.0/pages/help-centre/tags/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/marketing/website-2.0/pages/index.vue")
  },
  {
    name: "internet-entertainment",
    path: "/internet/entertainment",
    component: () => import("/builds/marketing/website-2.0/pages/internet/entertainment.vue")
  },
  {
    name: "internet-gamers-nbn",
    path: "/internet/gamers-nbn",
    component: () => import("/builds/marketing/website-2.0/pages/internet/gamers-nbn.vue")
  },
  {
    name: "internet-hardware",
    path: "/internet/hardware",
    component: () => import("/builds/marketing/website-2.0/pages/internet/hardware.vue")
  },
  {
    name: "internet-home-phone",
    path: "/internet/home-phone",
    component: () => import("/builds/marketing/website-2.0/pages/internet/home-phone.vue")
  },
  {
    name: "internet-nbn-fttp-upgrade",
    path: "/internet/nbn-fttp-upgrade",
    component: () => import("/builds/marketing/website-2.0/pages/internet/nbn-fttp-upgrade.vue")
  },
  {
    name: "internet-seniors-nbn",
    path: "/internet/seniors-nbn",
    component: () => import("/builds/marketing/website-2.0/pages/internet/seniors-nbn.vue")
  },
  {
    name: "internet-telstra",
    path: "/internet/telstra",
    component: () => import("/builds/marketing/website-2.0/pages/internet/telstra.vue")
  },
  {
    name: "investor-centre",
    path: "/investor-centre",
    component: () => import("/builds/marketing/website-2.0/pages/investor-centre/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/builds/marketing/website-2.0/pages/legal/index.vue")
  },
  {
    name: "legal-privacy-policy",
    path: "/legal/privacy-policy",
    component: () => import("/builds/marketing/website-2.0/pages/legal/privacy-policy.vue")
  },
  {
    name: "lp-big-tech-competition",
    path: "/lp/big-tech-competition",
    component: () => import("/builds/marketing/website-2.0/pages/lp/big-tech-competition.vue")
  },
  {
    name: "lp-black-friday",
    path: "/lp/black-friday",
    component: () => import("/builds/marketing/website-2.0/pages/lp/black-friday.vue")
  },
  {
    name: "lp-eofy",
    path: "/lp/eofy",
    component: () => import("/builds/marketing/website-2.0/pages/lp/eofy.vue")
  },
  {
    name: "lp-gamers-survey-2024",
    path: "/lp/gamers-survey-2024",
    component: () => import("/builds/marketing/website-2.0/pages/lp/gamers-survey-2024.vue")
  },
  {
    name: "lp",
    path: "/lp",
    component: () => import("/builds/marketing/website-2.0/pages/lp/index.vue")
  },
  {
    name: "media-centre-slug",
    path: "/media-centre/:slug(.*)*",
    component: () => import("/builds/marketing/website-2.0/pages/media-centre/[...slug].vue")
  },
  {
    name: "media-centre-all-articles",
    path: "/media-centre/all-articles",
    component: () => import("/builds/marketing/website-2.0/pages/media-centre/all-articles.vue")
  },
  {
    name: "media-centre",
    path: "/media-centre",
    component: () => import("/builds/marketing/website-2.0/pages/media-centre/index.vue")
  },
  {
    name: "mobile-slug",
    path: "/mobile/:slug()",
    component: () => import("/builds/marketing/website-2.0/pages/mobile/[slug].vue")
  },
  {
    name: "mobile-3g-network-change",
    path: "/mobile/3g-network-change",
    component: () => import("/builds/marketing/website-2.0/pages/mobile/3g-network-change.vue")
  },
  {
    name: "mobile-data-only-sim",
    path: "/mobile/data-only-sim",
    component: () => import("/builds/marketing/website-2.0/pages/mobile/data-only-sim.vue")
  },
  {
    name: "mobile-sim-only-plans",
    path: "/mobile/sim-only-plans",
    component: () => import("/builds/marketing/website-2.0/pages/mobile/sim-only-plans.vue")
  },
  {
    name: "moving-home",
    path: "/moving-home",
    component: () => import("/builds/marketing/website-2.0/pages/moving-home/index.vue")
  },
  {
    name: "myaussie-app",
    path: "/myaussie-app",
    component: () => import("/builds/marketing/website-2.0/pages/myaussie-app/index.vue")
  },
  {
    name: "network-connection-insights",
    path: "/network/connection-insights",
    component: () => import("/builds/marketing/website-2.0/pages/network/connection-insights.vue")
  },
  {
    name: "network-cvc-graphs",
    path: "/network/cvc-graphs",
    component: () => import("/builds/marketing/website-2.0/pages/network/cvc-graphs.vue")
  },
  {
    name: "network",
    path: "/network",
    component: () => import("/builds/marketing/website-2.0/pages/network/index.vue")
  },
  {
    name: "network-map",
    path: "/network/map",
    component: () => import("/builds/marketing/website-2.0/pages/network/map.vue")
  },
  {
    name: "network-mobile-coverage-map",
    path: "/network/mobile-coverage-map",
    component: () => import("/builds/marketing/website-2.0/pages/network/mobile-coverage-map.vue")
  },
  {
    name: "network-outages",
    path: "/network/outages",
    component: () => import("/builds/marketing/website-2.0/pages/network/outages.vue")
  },
  {
    name: "network-ping-times",
    path: "/network/ping-times",
    component: () => import("/builds/marketing/website-2.0/pages/network/ping-times.vue")
  },
  {
    name: "our-products",
    path: "/our-products",
    component: () => import("/builds/marketing/website-2.0/pages/our-products/index.vue")
  },
  {
    name: "refer-a-friend",
    path: "/refer-a-friend",
    component: () => import("/builds/marketing/website-2.0/pages/refer-a-friend/index.vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/builds/marketing/website-2.0/pages/reviews/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/builds/marketing/website-2.0/pages/search/index.vue")
  },
  {
    name: "speed",
    path: "/speed",
    meta: indexW3krUBdZfkMeta || {},
    component: () => import("/builds/marketing/website-2.0/pages/speed/index.vue")
  },
  {
    name: "ssbi",
    path: "/ssbi",
    component: () => import("/builds/marketing/website-2.0/pages/ssbi/index.vue")
  },
  {
    name: "tools-activate-sim",
    path: "/tools/activate-sim",
    component: () => import("/builds/marketing/website-2.0/pages/tools/activate-sim.vue")
  },
  {
    name: "tools-fibre-operations",
    path: "/tools/fibre-operations",
    meta: fibre_45operationsqFI0jryGl4Meta || {},
    component: () => import("/builds/marketing/website-2.0/pages/tools/fibre-operations.vue")
  },
  {
    name: "tools-release-form",
    path: "/tools/release-form",
    meta: release_45formehKlCRtviDMeta || {},
    component: () => import("/builds/marketing/website-2.0/pages/tools/release-form.vue")
  },
  {
    name: "wholesale-become-a-partner",
    path: "/wholesale/become-a-partner",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/become-a-partner.vue")
  },
  {
    name: "wholesale-carbon",
    path: "/wholesale/carbon",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/carbon.vue")
  },
  {
    name: "wholesale-carrier",
    path: "/wholesale/carrier",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/carrier/index.vue")
  },
  {
    name: "wholesale",
    path: "/wholesale",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/index.vue")
  },
  {
    name: "wholesale-lp",
    path: "/wholesale/lp",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/lp/index.vue")
  },
  {
    name: "wholesale-lp-number-porting",
    path: "/wholesale/lp/number-porting",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/lp/number-porting.vue")
  },
  {
    name: "wholesale-msp",
    path: "/wholesale/msp",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/msp/index.vue")
  },
  {
    name: "wholesale-partner-program",
    path: "/wholesale/partner-program",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/partner-program.vue")
  },
  {
    name: "wholesale-services-aussie-fibre",
    path: "/wholesale/services/aussie-fibre",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/services/aussie-fibre.vue")
  },
  {
    name: "wholesale-services-data",
    path: "/wholesale/services/data",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/services/data.vue")
  },
  {
    name: "wholesale-services-network-solutions",
    path: "/wholesale/services/network-solutions",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/services/network-solutions.vue")
  },
  {
    name: "wholesale-services-voice-carrier",
    path: "/wholesale/services/voice-carrier",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/services/voice-carrier.vue")
  },
  {
    name: "wholesale-services-voice",
    path: "/wholesale/services/voice",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/services/voice.vue")
  },
  {
    name: "wholesale-white-label",
    path: "/wholesale/white-label",
    component: () => import("/builds/marketing/website-2.0/pages/wholesale/white-label.vue")
  }
]